<template>
  <div>
    <Loader v-if="performingRequest" />
    <!-- <button class="btn btn__primary mt-3 mr-3" @click="updateClients()">update clients</button> -->
    <div class="mt-5">
      <div class="flex flex-wrap justify-space-between" style="width:100%;">
        <span class="mb-3">
          <!-- <button class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': showAll, 'btn__dark': !showAll }" @click="showAllSheets()" v-if="!showAll">Show All</button>
          <button class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': showAll, 'btn__dark': !showAll}" @click="hideAllSheets()" v-if="showAll">Show All</button>
 -->
          <div style="display:inline-block; width:180px;" class="mr-2">
          <v-select
            label="title" 
            :options="options"
            clearable
            v-model="type"
            placeholder="choose type"
            
            >
          </v-select>
          </div>

        </span>

      </div>
      <div class="flex flex-wrap justify-space-between" style="width:100%;" v-if="type">
        <span class="mb-3">

          <button v-if="!isShowDateFilter" class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': (startDate && endDate), 'btn__outlined': (!startDate || !endDate) }" @click="showDateFilter()">Date Filter <i class="fa-regular fa-sliders ml-2"></i></button>
          <button v-if="isShowDateFilter" class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': (startDate && endDate), 'btn__dark': (!startDate || !endDate) }" @click="hideDateFilter()">Hide Date Filter <i class="fa-regular fa-sliders ml-2"></i></button>

          <button v-if="!isShowOrderFilter" class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': filteredOrder, 'btn__outlined': !filteredOrder }" @click="showOrderFilter()">Refine By Order <i class="fa-regular fa-sliders ml-2"></i></button>
          <button v-if="isShowOrderFilter" class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': filteredOrder, 'btn__dark': !filteredOrder }" @click="hideOrderFilter()">Hide Order Filter <i class="fa-regular fa-sliders ml-2"></i></button>

          <button v-if="!isShowClientFilter" class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': filteredClient, 'btn__outlined': !filteredClient }" @click="showClientFilter()">Refine By Client <i class="fa-regular fa-sliders ml-2"></i></button>
          <button v-if="isShowClientFilter" class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': filteredClient, 'btn__dark': !filteredClient }" @click="hideClientFilter()">Hide Client Filter <i class="fa-regular fa-sliders ml-2"></i></button>

          <button v-if="!isShowSubClientFilter" class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': filteredSubClient, 'btn__outlined': !filteredSubClient }" @click="showSubClientFilter()">Refine By SubClient <i class="fa-regular fa-sliders ml-2"></i></button>
          <button v-if="isShowSubClientFilter" class="btn btn__small mr-3" v-bind:class="{ 'btn__primary': filteredSubClient, 'btn__dark': !filteredSubClient }" @click="hideSubClientFilter()">Hide SubClient Filter <i class="fa-regular fa-sliders ml-2"></i></button>
        </span>
      </div>
    </div>

    <transition name="fadeStop">
      <div v-if="isShowOrderFilter">
        <h4>Filter By Order:</h4>
        <div class="flex flex-row flex-wrap mt-3 mb-5">
          <div class="whiteBack" style="width:20%; min-width: 48rem;">
            <v-select
              label="title" 
              :options="events"
              clearable
              v-model="filteredOrder"
              >
            </v-select>
          </div>
          <div class="ml-5 mb-3" v-if="filteredOrder">
            <button class="btn btn__dark btn__small" @click="clearOrder()">Clear Order</button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fadeStop">
      <div v-if="isShowClientFilter">
        <h4>Filter By Client:</h4>
        <div class="flex flex-row flex-wrap mt-3 mb-5">
          <div class="whiteBack" style="width:20%; min-width: 48rem;">
            <v-select
              label="title" 
              :options="clients"
              clearable
              v-model="filteredClient"
              >
            </v-select>
          </div>
          <div class="ml-5 mb-3" v-if="filteredClient">
            <button class="btn btn__dark btn__small" @click="clearClient()">Clear Client</button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fadeStop">
      <div v-if="isShowSubClientFilter">
        <h4>Filter By SubClient:</h4>
        <div class="flex flex-row flex-wrap mt-3 mb-5">
          <div class="whiteBack" style="width:20%; min-width: 48rem;">
            <v-select
              label="title" 
              :options="subClients"
              clearable
              v-model="filteredSubClient"
              >
            </v-select>
          </div>
          <div class="ml-5 mb-3" v-if="filteredSubClient">
            <button class="btn btn__dark btn__small" @click="clearSubClient()">Clear SubClient</button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fadeStop">
      <div v-if="isShowDateFilter">
        <h4>Filter Dates:</h4>
        <div class="flex flex-row flex-wrap mt-3 mb-5">
          <div class="whiteBack">
            <label for="start">Start Date</label>
            <input type="date" v-model="startDate" />
          </div>
          <div class="whiteBack ml-5">
            <label for="end">End Date</label>
            <input type="date" v-model="endDate" />
          </div>
          <div class="ml-5 mb-3" v-if="endDate && startDate">
            <div class="mb-3">
            <button class="btn btn__primary" @click="submitDates()">Submit</button>
            </div>
            <div>
            <button class="btn btn__outlined btn__small" @click="clearDates()">Clear Dates</button>
            </div>
          </div>
          
        </div>
      </div>
    </transition>

      <div class="dashboard__container--body" v-if="allTheAssignments && allTheAssignments.length >= 1">

        <!-- <div v-for="(item, index) in allTheAssignments" :key="index">
          {{item.id}}
        </div> -->

        <!-- <PayrollTable v-if="(filteredAssignments && filteredAssignments.length >= 1) && !isHidden && !filteredOrder" :eventAssignments="allVisible" :type="type" /> -->

        <PayrollTable v-if="!isHidden" :eventAssignments="filteredAssignments" :type="type" />

        <!-- <PayrollTable v-if="isPaid" :eventAssignments="allPaid" type="Employee" /> -->

      </div>


  </div>
</template>

<style scoped>
  .vgt-selection-info-row {
    height: 3.28rem;
  }

.date-range-picker {
  display: inline-block;
  position: relative;
}

.date-range-picker input[type="date"] {
  width: 150px;
}

.selected-dates {
  margin-top: 10px;
}

.selected-dates .selected-date {
  display: inline-block;
  background-color: #007BFF;
  color: #fff;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 5px;
  cursor: pointer;
}
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
// import ExportService from "@/services/ExportService"
// import TimesheetNote from '@/components/Timesheets/TimesheetNote.vue'
import PayrollTable from '@/components/PayrollTable.vue'
import PayrollTableAlt from '@/components/PayrollTableAlt.vue'  
import firebase from 'firebase/app';
import algoliasearch from 'algoliasearch/lite';
// import * as moment from 'moment'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'reportTimesheets',
  data: () => ({
    searchClient: algoliasearch(
      '0T1SIY6Y1V',
      'f03dc899fbdd294d6797791724cdb402',
    ),
    startDate: '',
      endDate: '',
      selectedRange: [],
    options: ['Employees', 'Contractors'],
    newShiftDate: "",
    shiftDates: [],
    isShowAddRows: false,
    isShowDateFilter: false,
    isShowOrderFilter: false,
    isShowSubClientFilter: false,
    isShowClientFilter: false,
    isShowUserFilter: false,
    performingRequest: false,
    performingRequest2: false,
    showAll: false,
    isVisible: true,
    isHidden: false,
    isPaid: false,
    isVisibleByDay: true,
    isHiddenByDay: false,
    isPaidByDay: false,
    activeItem: null,
    activeDay: '',
    endDate: null,
    startDate: null,
    filteredOrder: null,
    filteredClient: null,
    filteredSubClient: null,
    filteredUser: null,
    newRow: {
      shift: null,
      users: [],
    },
    type: ''
  }),
  mounted() {
    if (!this.events || this.events.length == 0) {
      this.$store.dispatch("getEvents")
    }
    if (!this.clients || this.clients.length == 0) {
      this.$store.dispatch("getClients")
    }
    if (!this.subClients || this.subClients.length == 0) {
      this.$store.dispatch("getSubClients")
    }
    
  },
  // mounted () {
  //   if (this.eventAssignments || (this.eventAssignments && this.eventAssignments.length === '0')) {
  //     this.$store.dispatch("getEventAssignments", this.$route.params.id)
  //   }
  //   // this.$store.dispatch("getEventAssignments", this.$route.params.id)
  // },
  // mounted () {
  //   if (
  //     this.eventInfo &&
  //     this.eventInfo.id &&
  //     (!this.eventInfo.shiftType ||
  //       (this.eventInfo.shiftType !== 'Permanent' &&
  //         this.eventInfo.shiftType !== 'Job'))
  //   ) {
  //     this.$store.dispatch('getAssignmentDays', this.$route.params.id);
  //   }
  // },
  // mounted() {
  //   if (this.eventInfo && this.eventInfo.id && ((this.eventInfo.shiftType && (this.eventInfo.shiftType == 'Permanent' || this.eventInfo.shiftType == 'Job')))) {
  //     this.$store.dispatch("getEventGroupUsers", this.$route.params.id)
  //   }
  // },
  computed: {
    ...mapState(['allEmployeeAssignments', 'allContractorAssignments', 'events', 'clients', 'currentUser', 'userProfile', 'subClients']),
    uniqueGroupUsers() {
      // return Array.from(new Set(this.eventGroupUsers));
      if (this.eventGroupUsers && this.eventGroupUsers.length > 0) {

      const uniqueGroup = this.eventGroupUsers.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.userId === value.userId
        ))
      )

      let uniqueArray = [...new Set(uniqueGroup)];

      // const filteredUnique = uniqueGroup.filter(item => {
      //   return !item.hidden
      // })
      return uniqueArray

      // return this.eventGroupUsers.filter((item,
      //   index) => this.eventGroupUsers.indexOf(item) === index);
      // // let uniqueArray = [...new Set(this.eventGroupUsers)];
      // // let uniqueArray = []
      // // this.eventGroupUsers.forEach(item => {
      // //   if (!uniqueArray.includes(item)) {
      // //     uniqueArray.push(item);
      // //   }
      // // })
      // return uniqueArray
      } else {
        return null
      }
    },
    allTheAssignments() {
      return this.allEmployeeAssignments.concat(this.allContractorAssignments)
    },
    permShifts() {
      if (this.eventShifts && this.eventShifts.length > 1) {
        return this.eventShifts.filter(shift => {
          return (shift.type == 'Permanent' || shift.type == 'Job')
        })
      } else {
        return []
      }
    },
    // allVisible() {
    //   if (this.allTheAssignments && this.allTheAssignments.length >= 1) {
    //    return this.allTheAssignments.filter(item => {
    //       return (item && (!item.hidden) && (!item.paystatus || (item.paystatus != 'paid')))
    //     })
    //   } else {
    //     return 
    //   }
    // },
    allVisible() {
      if (this.allTheAssignments && this.allTheAssignments.length >= 1) {
       return this.allTheAssignments.filter(item => {
          return (item && (!item.hidden))
        })
      } else {
        return 
      }
    },
    allHidden() {
      if (this.allTheAssignments && this.allTheAssignments.length > 0) {
        return this.allTheAssignments.filter(item => {
          return (item.hidden)
        })
      } else {
        return []
      }
    },
    allPaid() {
      if (this.allTheAssignments && this.allTheAssignments.length > 0) {
        return this.allTheAssignments.filter(item => {
          return ((!item.hidden || item.hidden != true) && (item.paystatus && item.paystatus === 'paid'))
        })
      } else {
        return []
      }
    },
    visibleAssignments() {
      if (this.showAll && this.allTheAssignments && this.allTheAssignments.length > 0) {
        return this.allTheAssignments.filter(item => {
          return ((!item.hidden || item.hidden != true) && (!item.paystatus || item.paystatus != 'paid'))
        })
      } else {
        return []
      }
    },
    hiddenAssignments() {
      if (this.showAll && this.allTheAssignments && this.allTheAssignments.length > 0) {
        return this.allTheAssignments.filter(item => {
          return (item.hidden)
        })
      } else {
        return []
      }
    },

    paidAssignments() {
      if (this.showAll && this.allTheAssignments && this.allTheAssignments.length > 0) {
        return this.allTheAssignments.filter(item => {
          return (item.paystatus === 'paid')
        })
      } else {
        return []
      }
    },


    visibleAssignmentsByDay() {
      if (this.isVisibleByDay && this.activeDay && !this.showAll && this.allTheAssignmentsByDay && this.allTheAssignmentsByDay.length > 0) {
        return this.allTheAssignmentsByDay.filter(item => {
          return ((!item.hidden || item.hidden != true) && (!item.paystatus || item.paystatus != 'paid') && item.day == this.activeDay)
        })
      } else {
        return []
      }
    },
    hiddenAssignmentsByDay() {
      if (this.isHiddenByDay && this.activeDay && !this.showAll && this.allTheAssignmentsByDay && this.allTheAssignmentsByDay.length > 0) {
        return this.allTheAssignmentsByDay.filter(item => {
          return (item.hidden && item.day == this.activeDay)
        })
      } else {
        return []
      }
    },

    paidAssignmentsByDay() {
      if (this.isPaidByDay && this.activeDay && !this.showAll && this.allTheAssignmentsByDay && this.allTheAssignmentsByDay.length > 0) {
        return this.allTheAssignmentsByDay.filter(item => {
          return (item.paystatus == 'paid' && item.day == this.activeDay)
        })
      } else {
        return []
      }
    },
    assignmentsByDate() {
      if (this.startDate && this.endDate && this.allVisible && this.allVisible.length > 0) {
        let startDate = new Date(this.startDate);
        let endDate = new Date(this.endDate);
      
        return this.allVisible.filter(item => {
          let eventDate = new Date(item.date)
          return ((eventDate >= startDate) && (eventDate <= endDate)) 
        })
      } else {
        return []
      }
    },
    assignmentsByOrder() {
      if (this.filteredOrder && this.allVisible && this.allVisible.length > 0) {
        return this.allVisible.filter(item => {
          return item.eventId == this.filteredOrder.id
        })
      } else {
        return []
      }
    },
    assignmentsByClient() {
      if (this.filteredClient && this.allVisible && this.allVisible.length > 0) {
        return this.allVisible.filter(item => {
          return item.clientId == this.filteredClient.id
        })
      } else {
        return []
      }
    },
    assignmentsBySubClient() {
      if (this.filteredSubClient && this.allVisible && this.allVisible.length > 0) {
        return this.allVisible.filter(item => {
          return item.subClientId == this.filteredSubClient.id
        })
      } else {
        return []
      }
    },
    assignmentsByUser() {
      if (this.filteredUser) {
        return this.eventGroupUsers.filter(item => {
          return item.userId == this.filteredUser.userId
        })
      } else {
        return []
      }
    },
    filteredAssignments() {
      const usersArray = this.assignmentsByUser || [];
      const ordersArray = this.assignmentsByOrder || [];
      const datesArray = this.assignmentsByDate || [];
      const clientsArray = this.assignmentsByClient || [];
      const subClientsArray = this.assignmentsBySubClient || [];

      // Filter assignments based on users, shifts, and dates
      let filteredArray = [...usersArray, ...ordersArray, ...datesArray, ...clientsArray];

      // If usersArray is not empty, filter by users
      if (usersArray.length > 0) {
        filteredArray = filteredArray.filter((assignment) => usersArray.includes(assignment));
      }

      // If ordersArray is not empty, filter by orders
      if (ordersArray.length > 0 || this.filteredOrder) {
        filteredArray = filteredArray.filter((assignment) => ordersArray.includes(assignment));
      }

      // If datesArray is not empty, filter by dates
      if (datesArray.length > 0) {
        filteredArray = filteredArray.filter((assignment) => datesArray.includes(assignment));
      }

      // If datesArray is not empty, filter by client
      if (clientsArray.length > 0 || this.filteredClient) {
        filteredArray = filteredArray.filter((assignment) => clientsArray.includes(assignment));
      }

      if (subClientsArray.length > 0 || this.filteredSubClient) {
        filteredArray = filteredArray.filter((assignment) => subClientsArray.includes(assignment));
      }

      return filteredArray;
    }

  },
  components: {
    Loader,
    PayrollTable,
    PayrollTableAlt
  },
  
  methods: {
    // updateClients() {
    //   fb.assignmentsCollection
    //   .get().then(querySnapshot => {
    //     querySnapshot.forEach(doc => {
    //       if (!doc.data().clientId) {
    //         fb.eventsCollection.doc(doc.data().eventId)
    //         .get().then(item => {
    //           console.log(item.id)
    //           fb.assignmentsCollection.doc(doc.id).update({
    //             clientId: item.client[0].id,
    //             subClientId: item.subClient[0].id,
    //           })
    //         })
    //       }
    //     })
    //   })
    // },
    async updateClients() {
      console.log('updateClients')
      const updateAssignmentWithClientIds = firebase.functions().httpsCallable('updateAssignmentWithClientIds')
      await updateAssignmentWithClientIds()
    },
    
    
    // async chooseOrder() {
    //   this.performingRequest = true
    //   this.performingRequest = false
    // },
    // async loadInitial() {
    //   this.performingRequest = true;
    //   await this.$store.dispatch("getallTheAssignments", this.type)
    //   if (this.allTheAssignments && this.allTheAssignments.length >= 1) {
    //     this.performingRequest = false
    //   }
    // },
    async submitDates() {
      this.performingRequest = true;
      await this.$store.dispatch("getallEmployeeAssignmentsByDate", {
        startDate: this.startDate,
        endDate: this.endDate,
        type: this.type
      })
      this.performingRequest = false
    },
    addUser(item) {
      this.performingRequest2 = true;
      console.log(item)
      item.userId = item.objectID
      let users = this.newRow.users
      users.push(item)
      setTimeout(() => {
        this.performingRequest2 = false;
        document
        .querySelectorAll('.ais-SearchBox-input')
        .forEach((e) => (e.value = ''))
        document.querySelectorAll('.ais-Hits-item').forEach((e) => e.remove())
      }, 250)
    },
    addRange() {
      if (this.startDate && this.endDate) {
        const start = new Date(this.startDate);
        const end = new Date(this.endDate);
        let currentDate = new Date(start);

        while (currentDate <= end) {
          this.selectedRange.push({
            start: new Date(currentDate).toISOString().split('T')[0],
            end: new Date(currentDate).toISOString().split('T')[0],
          });

          // Increment the current date
          currentDate.setDate(currentDate.getDate() + 1);
        }

        this.clearInputs();
      }
    },
    removeRange(index) {
      this.selectedRange.splice(index, 1);
    },
    removeUser(index) {
      this.newRow.users.splice(index, 1);
    },
    clearInputs() {
      this.startDate = '';
      this.endDate = '';
    },
    async testMessage() {
      var startSMSConversation = firebase.functions().httpsCallable('startSMSConversation');
      let phone = this.userProfile.phone
      await startSMSConversation({
        recipient: `+19252122908`,
        userId: this.userProfile.id,
      })
    },
    // async getDynamicUsers() {
    //   this.performingRequest = true
    //   // await this.$store.dispatch("clearStaticallTheAssignments")
    //   await this.$store.dispatch("getallTheAssignments", this.$route.params.id)
    //   this.performingRequest = false
    // },
    // async getUsers() {
    //   this.performingRequest = true
    //   // await this.$store.dispatch("clearallTheAssignments")
    //   await this.$store.dispatch("getStaticallTheAssignments", this.$route.params.id)
    //   this.performingRequest = false
    // },
    removeDay(index) {
      this.shiftDates.splice(index, 1);
    },
    onPickDate() {
      this.shiftDates.push(this.newShiftDate)
      this.newShiftDate = ''
    },

    // async lockJob(props, shift) {
    //   props.row.status = "spinning"
    //   var lockJob = firebase.functions().httpsCallable('lockJob');
    //   await lockJob({
    //     event: this.event,
    //     day: this.activeDay,
    //     row: props.row,
    //     shift: shift,
    //   })
    //   .then((result) => {
    //     props.row.status = "assigned"
    //   })
    // },

  //   async buildRows() {
  //   console.log(this.newRow);
  //   this.performingRequest = true;

  //    const selectedRangeStarts = this.selectedRange.map(range => range.start);

  //   // Create the matrix using map and flatMap
  //   const matrix = this.newRow.users.flatMap(user =>
  //     selectedRangeStarts.map(date => ({ user, date }))
  //   );

  //   console.log(matrix)

  //   await this.addRows(matrix);
  //   this.selectedRange = []
  //   this.startDate = ''
  //   this.endDate = ''
  //   return matrix;
  // },
  // async addRows(matrix) {

  //     const promises = matrix.map(async (item) => {
  //     const fullName = item.user.firstName + ' ' + item.user.lastName;
  //     // item.user.realId = item.userId;
  //     // item.user.fullName = fullName;

  //     const theUser = {
  //       userId: item.user.userId,
  //       email: item.user.email,
  //       phone: item.user.phone,
  //       firstName: item.user.firstName,
  //       lastName: item.user.lastName,
  //       fullName: fullName,
  //     }

  //     console.log(theUser)

  //     const lockNewRows = firebase.functions().httpsCallable('lockNewRows');
  //     await lockNewRows({
  //       event: this.eventInfo,
  //       day: item.date,
  //       row: theUser,
  //       job: this.newRow.job,
  //       sendPlacementNotifications: false,
  //     });
  //   });

  //   // Use Promise.all to execute all the lockJob calls in parallel
  //   await Promise.all(promises);


  //   // Reset state after all requests are complete
  //   this.newRow = {};
  //   this.newShiftDate = null;
  //   this.shiftDates = [];
  //   this.performingRequest = false;
  // },

//   async addRows(matrix) {
//     console.log(matrix);

//     const lockJob = this.eventInfo && this.eventInfo.id &&
//       (!this.eventInfo.shiftType || (this.eventInfo.shiftType !== 'Permanent' && this.eventInfo.shiftType !== 'Job'))
//       ? firebase.functions().httpsCallable('lockShift')
//       : firebase.functions().httpsCallable('lockJob');

//     const promises = matrix.map(async (item) => {
//     const fullName = item.user.firstName + ' ' + item.user.lastName;
//     item.user.realId = item.user.id;
//     item.user.fullName = fullName;

//     await lockJob({
//       event: this.eventInfo,
//       day: item.date,
//       row: item.user,
//       shift: this.newRow.shift,
//       sendPlacementNotifications: false,
//     });
//   });

//   // Use Promise.all to execute all the lockJob calls in parallel
//   await Promise.all(promises);

//   // Reset state after all requests are complete
//   this.newRow = {};
//   this.newShiftDate = null;
//   this.shiftDates = [];
//   this.performingRequest = false;
// },

    // async showAllSheets() {
    //   this.performingRequest = true
    //   this.showAll = true
    //   this.activeDay = ''
    //   await this.$store.dispatch("clearallTheAssignmentsByDay")
    //   await this.$store.dispatch("getallTheAssignments", this.$route.params.id)
    //   // if (!this.allTheAssignments || this.allTheAssignments.length < 1) {
    //   //   await this.$store.dispatch("getallTheAssignments", this.$route.params.id)
    //   // }
    //    this.performingRequest = false
    //   // this.$store.dispatch("getallTheAssignments", this.$route.params.id)
    // },
    // async showAllSheets() {
    //   this.performingRequest = true
    //   this.showAll = true
    //   this.activeDay = ''
    //   await this.$store.dispatch("getallTheAssignments")
    //   // if (!this.eventAssignments || this.eventAssignments.length < 1) {
    //   //   await this.$store.dispatch("getEventAssignments", this.$route.params.id)
    //   // }
    //    this.performingRequest = false
    //   // this.$store.dispatch("getEventAssignments", this.$route.params.id)
    // },
    // async hideAllSheets() {
    //    this.performingRequest = true
    //   await this.$store.dispatch('clearEventAssignments')
    //   this.showAll = false
    //    this.isShowUserFilter = false
    //   this.isShowDateFilter = false
    //   this.isShowShiftFilter = false
    //   this.isShowAddRow = false
    //   this.performingRequest = false
    //   // this.$store.dispatch("clearEventAssignments")
    // },
    // async updateDay() {
    //   this.performingRequest = true
      
    //   this.isShowShiftFilter = false
    //   this.isShowDateFilter = false
    //   this.isShowUserFilter = false
    //   this.isShowAddRows = false
    //   // if (this.activeDay) {
    //   //   await this.$store.dispatch("getEventAssignmentsByDay", {
    //   //     eventId: this.eventInfo.id,
    //   //     day: this.activeDay
    //   //   })
    //   // } else {
    //   //   // this.$store.dispatch('clearEventAssignmentsByDay')
    //   // }
    //   setTimeout(() => {
    //     this.performingRequest = false
    //   }, 2000)
    // },
    showUserFilter() {
      // if (!this.eventGroupUsers || this.eventGroupUsers.length == 0) {
      //   this.$store.dispatch("getEventGroupUsers", this.$route.params.id)
      // }
      this.showAll = true
      this.isShowUserFilter = true
      this.isShowDateFilter = false
      this.isShowOrderFilter = false
      this.isShowAddRows = false
      this.isHidden = false
      this.isVisible = true
    },
    async hideUserFilter() {
      this.isShowUserFilter = false
      await this.$store.dispatch("clearEventGroupUsers")
    },
    clearUser() {
      this.showAll = false
      this.filteredUser = null
    },
    showOrderFilter() {
      this.showAll = true
      this.isShowOrderFilter = true
      this.isShowClientFilter = false
      this.isShowDateFilter = false
      this.isShowUserFilter = false
      this.isShowSubClientFilter = false
      this.isShowAddRows = false
      this.isHidden = false
      this.isVisible = true
    },
    hideOrderFilter() {
      this.showAll = false
      this.isShowOrderFilter = false
    },
    
    clearOrder() {
      this.filteredOrder = null
    },
    hideClientFilter() {
      this.showAll = false
      this.isShowClientFilter = false
    },
    clearClient() {
      this.filteredClient = null
    },
    hideSubClientFilter() {
      this.showAll = false
      this.isShowSubClientFilter = false
    },
    clearSubClient() {
      this.filteredSubClient = null
    },
    showDateFilter() {
      this.showAll = true
      this.isShowDateFilter = true
      this.isShowOrderFilter = false
      this.isShowClientFilter = false
      this.isShowUserFilter = false
      this.isShowAddRows = false
      this.isShowSubClientFilter = false
      this.isHidden = false
      this.isVisible = true
    },
    hideDateFilter() {
      this.showAll = false
      this.isShowDateFilter = false
    },
    clearDates() {
      this.startDate = null
      this.endDate = null
    },
    showClientFilter() {
      this.showAll = true
      this.isShowDateFilter = false
      this.isShowOrderFilter = false
      this.isShowClientFilter = true
      this.isShowSubClientFilter = false
      this.isShowUserFilter = false
      this.isShowAddRows = false
      this.isHidden = false
      this.isVisible = true
    },
    showSubClientFilter() {
      this.showAll = true
      this.isShowDateFilter = false
      this.isShowOrderFilter = false
      this.isShowClientFilter = false
      this.isShowSubClientFilter = true
      this.isShowUserFilter = false
      this.isShowAddRows = false
      this.isHidden = false
      this.isVisible = true
    },
    showAddRows() {
      // if (this.eventInfo && this.eventInfo.id && ((this.eventInfo.OrderType && (this.eventInfo.OrderType == 'Permanent' || this.eventInfo.OrderType == 'Job'))) && (!this.eventGroupUsers || this.eventGroupUsers.length == 0)) {
      //   this.$store.dispatch("getEventGroupUsers", this.$route.params.id)
      // }
      this.isShowAddRows = true
      this.isShowDateFilter = false
      this.isShowOrderFilter = false
      this.isHidden = false
      this.isVisible = true
    },
    hideAddRows() {
      this.isShowAddRows = false
    },
    showHiddenPerm() {
      this.isShowDateFilter = false
      this.isShowAddRows = false
      this.isHidden = true
      this.isVisible = false
      this.isPaid = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showHidden() {
      this.isShowDateFilter = false
      this.isShowAddRows = false
      this.isHidden = true
      this.isVisible = false
      this.isPaid = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showVisiblePerm() {
      this.isVisible = true
      this.isHidden = false
      this.isPaid = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showVisible() {
      this.isVisible = true
      this.isHidden = false
      this.isPaid = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showPaid() {
      this.isPaid = true
      this.isVisible = false
      this.isHidden = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showPaidPerm() {
      this.isPaid = true
      this.isVisible = false
      this.isHidden = false
      this.isPaidByDay = false
      this.isVisibleByDay = false
      this.isHiddenByDay = false
    },
    showHiddenByDay() {
      if (this.activeDay) {
        this.isHiddenByDay = true
        this.isVisibleByDay = false
        this.isPaidByDay = false
        this.isHidden = false
        this.isVisible = false
        this.isPaid = false
      }
    },
    showVisibleByDay() {
      if (this.activeDay) {
        this.isVisibleByDay = true
        this.isHiddenByDay = false
        this.isPaidByDay = false
        this.isHidden = false
        this.isVisible = false
        this.isPaid = false
      }
    },
    showPaidByDay() {
      if (this.activeDay) {
        this.isPaidByDay = true
        this.isVisibleByDay = false
        this.isHiddenByDay = false
        this.isHidden = false
        this.isVisible = false
        this.isPaid = false
      }
    },
  },
  beforeDestroy () {
    this.$store.dispatch("clearAllAssignments")
    this.performingRequest = null
    delete this.performingRequest
    this.isVisible = null
    delete this.isVisible
    this.isHidden = null
    delete this.isHidden
    this.isVisibleByDay = null
    delete this.isVisibleByDay
    this.isHiddenByDay = null
    delete this.isHiddenByDay
    this.isPaid = null
    delete this.isPaid
    this.isPaidByDay = null
    delete this.isPaidByDay
    this.activeItem = null
    delete this.activeItem
    this.activeDay = null
    delete this.activeDay
    delete this.setStartDay
    delete this.updateDay
    delete this.showHidden
    delete this.showVisible
    delete this.showPaid
    delete this.showHiddenByDay
    delete this.showVisibleByDay
    delete this.showPaidByDay
    // delete this.formatDate
    delete this.exportRegisterByDay
    delete this.exportRegister
    delete this.visibleAssignmentsByDay
    delete this.hiddenAssignmentsByDay
    delete this.paidAssignmentsByDay
    this.newShiftDate = null
    delete this.newShiftDate
    this.shiftDates = null
    delete this.shiftDates
    this.isShowAddRows = null
    delete this.isShowAddRows
    this.isShowDateFilter = null
    delete this.isShowDateFilter
    this.isShowShiftFilter = null
    delete this.isShowShiftFilter
    this.isShowUserFilter = null
    delete this.isShowUserFilter
    this.endDate = null
    delete this.endDate
    this.startDate = null
    delete this.startDate
    this.filteredOrder = null
    delete this.filteredOrder
    this.filteredUser = null
    delete this.filteredUser
    delete this.permShifts
    this.newRow = null
    delete this.newRow
    this.startDate = null
    this.endDate = null
    delete this.endDate
    delete this.startDate
    console.log(this)
  },
  errorCaptured(err, vm, info) {
    // Handle errors here
    console.error('Error Captured Hook:', err, vm, info);
  },
}
</script>